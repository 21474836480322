body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: 'Hey Comic';
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New',
    monospace;
}
