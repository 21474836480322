@font-face {
  font-family: myMainFont;
  src: url('../src/font/HeyComic.otf');
}

@font-face {
  font-family: myDesFont;
  src: url('../src/font/delius-swash-caps.regular.ttf');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: myMainFont; */
}

:root {
  --mainBg: #ff33bb;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: myMainFont, cursive;
}

html {
  scroll-behavior: smooth;
  position: absolute;
}

body {
  position: relative !important;
  overflow-y: none !important;
}

.custom-nav-border {
  background: #fff;
  width: 100% !important;
}

.custom-nav-border::after {
  /* background: repeating-linear-gradient(0deg, orange 5%, purple 5%, green 10%, red 10%, red 15%, teal 15%); */
  background: repeating-linear-gradient(
    -90deg,
    orange,
    purple 5%,
    green 10%,
    #cc0088 10%,
    teal 10%
  );
  position: absolute;
  content: '';
  height: 2px;
  right: 0;
  left: 0;
  top: 100%;
  z-index: -1;
}

.custom-hero-info {
  border: 1px solid #cdcdcd;
  box-shadow: 5px 5px #cdcdcd;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-b-30 {
  margin-bottom: 30px;
}

.mg-b-20 {
  margin-bottom: 20px;
}
.mg-b-40 {
  margin-bottom: 40px !important;
}

.mg-bt-100 {
  margin-bottom: 100px;
}

.mg-t-50 {
  margin-top: 50px !important;
}
.mg-t-30 {
  margin-top: 30px !important;
}
.mg-t-10 {
  margin-top: 10px !important;
}
.mg-t-mn-10 {
  margin-top: -10px !important;
}
.mg-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mg-t-15 {
  margin-top: 15px !important;
}
.mg-bt-15 {
  margin-bottom: 15px !important;
}
.mg-bt-m-20 {
  margin-bottom: -20px !important;
}

p {
  font-size: 18px;
}

span {
  font-size: 18px;
}

p,
span {
  font-family: myDesFont;
  font-weight: normal !important;
}

.center-text {
  text-align: center !important;
}

.special-team-members {
  justify-content: center;
}

.mg-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mg-100 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.fusioncharts-container > g:nth-last-child() {
  display: none !important;
}

.tokenomic-chart {
  width: 1000px !important;
  height: 800px;
}

.raphael-group-19-legend {
  width: 400px !important;
}

@media screen and (min-width: 768px) {
  #chartobject-1 {
    zoom: 1.5 !important;
  }
  html,
  body {
    overflow-y: none !important;
  }
  .responsive-iframe {
    transform: translate(-250px, -300px);
  }
  .tokenomic-chart-mobile {
    display: none;
  }
  .tokenomic-chart {
    display: block;
  }
  .tokenomic-chart-touch-device {
    display: none;
  }
}

@media screen and (max-width: 767.99px) {
  .tokenomic-chart {
    display: none;
  }
  .responsive-iframe {
    width: 350px !important;
    height: auto;
    transform: translate(10px, -250px) !important;
  }

  .tokenomic-chart-mobile {
    display: block;
    transform: translateX(-30px) !important;
  }
  .tokenomic-chart-touch-device {
    display: none;
  }
  body,
  html {
    max-width: 100%;
    justify-content: center !important;
    position: relative;
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .tokenomic-chart {
    transform: translateX(-50px);
  }
}

@media screen and (max-width: 1024px) {
  .tokenomic-chart {
    width: 950px !important;
    height: 500px !important;
    transform: translate(-100px, 50px) scale(1.2, 1.2) !important;
  }
}

@media screen and (max-width: 1200px) {
  .tokenomic-chart {
    width: 950px !important;
    height: 500px !important;
    transform: translate(-280px, 50px) scale(1) !important;
  }
}

@media screen and (min-width: 1202px) and (max-width: 1399px) {
  .tokenomic-chart {
    width: 950px !important;
    height: 500px !important;
    transform: translate(-300px, 50px) scale(1.15) !important;
  }
}
@media screen and (min-device-width: 1400px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .tokenomic-chart {
    width: 950px !important;
    height: 500px !important;
    transform: translate(-25px, 50px) scale(1.15) !important;
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .tokenomic-chart {
    width: 950px !important;
    height: 500px !important;
    transform: translate(-350px, 50px) scale(1.15) !important;
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1800px) {
  .tokenomic-chart {
    width: 950px !important;
    height: 500px !important;
    transform: translate(-330px, 50px) scale(1.15) !important;
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 1801px) {
  .tokenomic-chart {
    width: 950px !important;
    height: 500px !important;
    transform: translate(-315px, 50px) scale(1.15) !important;
    -webkit-transform: translate(-315px, 50px) scale(1.15) !important;
    -moz-transform: translateY(30px) scale(1.15) !important;
    margin-top: 20px !important;
  }
}

.vertical-timeline-element-date {
  color: rgba(144, 13, 31, 1);
  font-weight: bold !important;
  font-size: 20px !important;
}

g[pointer-events='bounding-box'] {
  display: none;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  html,
  body {
    scroll-behavior: smooth;
  }
  .responsive-iframe {
    width: 800px;
    height: 500px;
    transform: translate(-260px, -270px);
  }
  .tokenomic-chart-mobile {
    display: none;
  }
  .tokenomic-chart {
    display: none;
  }
  .tokenomic-chart-touch-device {
    display: block;
    margin-left: 30%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  html,
  body {
    scroll-behavior: smooth;
  }
  .responsive-iframe {
    width: 800px !important;
    height: 500px;
    transform: translate(-165px, -300px);
  }
  .tokenomic-chart-mobile {
    display: none;
  }
  .tokenomic-chart {
    display: none;
  }
  .tokenomic-chart-touch-device {
    display: block;
    margin-left: 50px;
  }
}

.video-responsive {
  position: relative;
  width: 100%;
}

.modal-content {
  background: transparent !important;
  border: none !important;
}

.responsive-iframe {
  width: 1000px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition-timing-function: ease-in-out;
}

.footer-icon-facebook {
  color: #0f92f3;
}

.footer-icon-youtube {
  color: #ff0000;
}

.footer-icon-telegram {
  color: #0088cc;
}

.footer-icon-whitepaper {
  color: #ffffff;
}

.footer-icon-reddit {
  color: #ff4500;
}

.mobile-footer {
  display: none !important;
}

@media screen and (max-width: 500px) {
  .mobile-footer {
    display: block !important;
  }
}
.white-bg {
  background: #fff;
  width: 100%;
}
.main-bg {
  background: #000;
  /* background-image: url('../src/images/bg/BackGroundWeb/Texture02.png'); */
  background-repeat: repeat;
  z-index: -1;
  background-position: 300px 0px;
  position: absolute;
  width: 100%;
  height: 100%;
}
.mg-bt-40 {
  margin-bottom: 40px;
}

#root {
  max-width: 100%;
  overflow-x: hidden !important;
  padding-left: 2px;
  padding-right: 2px;
  /* justify-content: center; */
}

@media screen and (max-width: 768px) {
  body,
  html {
    overflow-x: hidden !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.pd-t-25 {
  padding-top: 25px;
}
.mg-b-40 {
  margin-bottom: 30px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mg-l-16 {
    margin-left: 16%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .mg-l-16 {
    margin-left: 0%;
  }
  body,
  html {
    padding-right: 2px;
    padding-left: 2px;
  }
}
@media screen and (max-width: 768px) {
  .mg-l-16 {
    margin-left: 0%;
  }
}

.slash-3 {
  height: 10px;
  background-image: linear-gradient(
    45deg,
    rgba(13, 13, 13, 0),
    rgba(13, 13, 13, 0) 33.33%,
    #0d0d0d 33.33%,
    #0d0d0d 66.67%,
    rgba(13, 13, 13, 0) 66.67%,
    rgba(13, 13, 13, 0) 100%
  );
  background-size: 10px 100%;
  width: 250px;
}

.w-100 {
  width: 100%;
}

/*divider*/
.astrodivider {
  margin: 64px auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-top: 80px !important;
}

.astrodividermask {
  overflow: hidden;
  height: 10px;
}

.astrodividermask:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px / 12px;
  box-shadow: 0 0 8px #049372;
}
.astrodivider span {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -25px;
  left: 50%;
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #4fb39c;
  background: #fff;
}
.astrodivider i {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
  border: 1px dashed #68beaa;
  text-align: center;
  line-height: 40px;
  font-style: normal;
  color: #049372;
}
/*end divider*/

/*HeroAnimation*/
.area {
  background: #fff;
  background: -webkit-linear-gradient(to left, #fff, #fff);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .area {
    display: none;
  }
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 40px;
  height: 40px;
  background: rgba(85, 231, 255, 0.6);
  animation: animate 25s linear infinite;
  bottom: -150px;
  border-radius: 35% !important;
}

.pink-color {
  background: rgba(255, 0, 166, 0.6) !important;
}
.blue-color {
  background: rgba(0, 249, 255, 0.6) !important;
}
.purple-color {
  background: rgba(175, 61, 255, 0.6) !important;
}
.green-neo-color {
  background: rgba(21, 255, 0, 0.6) !important;
}
.green-color {
  background: rgba(170, 255, 0, 0.6) !important;
}

.circles li:nth-child(1) {
  left: 5%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 20%;
  width: 60px;
  height: 60px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 15%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
/*End HeroAnimation*/
/*Road map*/
p.roadmap-li {
  display: inline-flex;
}
p.active::before {
  color: #fff !important;
}
p.roadmap-li::before {
  content: '•';
  color: #000;
  padding-right: 5px;
  font-size: 18px;
  vertical-align: middle;
}
/*End road map*/
.mg-r-5 {
  padding-right: 5px !important;
}
.text-orange-color {
  color: #fe8101;
  font-size: 24px;
  font-weight: bold !important;
}

.text-ton-color {
  color: #0088cc;
  font-size: 24px;
  font-weight: bold !important;
}

.text-blue-color {
  color: #52bbe7;
  font-size: 24px;
  font-weight: bold !important;
}
.game-company-link {
  text-decoration: none;
}
